.dropmenu{
    width: 60%;
    height: 100vh;
    position: fixed;
    padding-left: 5%;
    list-style: none;
    margin-left: 61%;
    z-index: 1;
    margin-top: -12px;
    background-color: rgb(49, 53, 53);

    @media (max-width: 1113px) {
        width: 60%;
        height: 100vh;
        list-style: none;
      }
    @media (max-width: 920px) {
        margin-left: 41%;
      }
    @media (max-width: 600px) {
        margin-left: 31%;
      }

    @media (max-width: 450px) {
        width: 69%;
        height: 100vh;
        list-style: none;
        margin-left: 38%;
      }
}


.li{
    background-color: rgb(49, 53, 53);
    cursor: pointer;
    display: block;
    width: 100%;
    height: 10%;
    text-decoration: none;
    color: white;
    padding-top: 5%;
}


.dropmenu li:hover{
   background-color: rgb(34, 39, 39);
}

.dropmenu.clicked{
    display: none;
}

.OpenLinkButton {
width: 40px;
height: 20px;
background: none;
border: none;
color: white;
font-size: 25px;
cursor: pointer;
text-align: end;
display: none;
@media (max-width: 1113px) {
    display: block;
    margin-bottom: 15px;
  }
@media (max-width: 1101px) {
    margin-bottom: 28px;
  }
@media (max-width: 411px) {
    margin-bottom: 0px;
    font-size: 26px;

  }
}

.closeButton{
    padding-bottom: 2em;
    margin-left: 65%;
    margin-top: 5px;
}