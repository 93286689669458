.Header {
  position: fixed;
  margin-left: 90%;
  padding-top: 12px;
  /* background-color: white; */
}

.OpenLinkButton {
  width: 40px;
  height: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 25px;
  cursor: pointer;
  text-align: end;
  display: none;

  @media (max-width: 1080px) {
    display: block;
    margin-bottom: 15px;
  }

  @media (max-width: 900px) {
    margin-bottom: 28px;
  }

  @media (max-width: 411px) {
    margin-bottom: 0px;
    font-size: 26px;

  }
}
