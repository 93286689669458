.MenuShowHide{
    width: 300px;
    background-color: rgb(18, 20, 24);
    padding-top: 30px;
    display: block;
    @media (max-width: 1080px) {
        display: none;
      }
   
}

.MenuShowHide.open {
    width: 300px;
    background-color: rgb(18, 20, 24);
    padding-top: 30px;
    display: block;
    position: fixed;
    height: 100%;
    z-index: 3;
}